a.disabled {
  color: #ccc !important;
  cursor: text;
}
.font-16 {
  font-size: 16px;
}
.line-height-large {
  line-height: 22px;
}
.cursor-pointer {
  cursor: pointer;
}
.p-25 {
  padding: 25px;
}
.p-left-10 {
  padding-left: 10px !important;
}
.p-left-20 {
  padding-left: 20px !important;
}
.no-padding-left {
  padding-left: 0;
}
.no-padding-right {
  padding-right: 0;
}
.p-left-30 {
  padding-left: 30px;
}
.p-10 {
  padding: 10px;
}
.p-bottom-10 {
  padding-bottom: 10px;
}
.p-bottom-20 {
  padding-bottom: 20px;
}
.p-bottom-30 {
  padding-bottom: 30px;
}
.p-top-4 {
  padding-top: 4px;
}
.p-top-10 {
  padding-top: 10px;
}
.p-top-20 {
  padding-top: 20px;
}
.p-top-30 {
  padding-top: 30px;
}
.p-left-200 {
  padding-left: 200px;
}
.p-right-200 {
  padding-right: 200px;
}
.disp-none {
  display: none;
}
.m-top-6 {
  margin-top: 6px !important;
}
.m-top-10 {
  margin-top: 10px !important;
}
.m-top-15 {
  margin-top: 15px !important;
}
.m-top-20 {
  margin-top: 20px !important;
}
.m-top-26 {
  margin-top: 26px !important;
}
.m-top-30 {
  margin-top: 30px !important;
}
.m-top-35 {
  margin-top: 35px !important;
}
.m-top-40 {
  margin-top: 40px !important;
}
.m-top-50 {
  margin-top: 50px !important;
}
.m-left-10 {
  margin-left: 10px !important;
}
.m-left-20 {
  margin-left: 20px !important;
}
.m-left-50 {
  margin-left: 50px !important;
}
.m-right-10 {
  margin-right: 10px !important;
}
.m-right-15 {
  margin-right: 15px !important;
}
.m-right-20 {
  margin-right: 20px !important;
}
.m-right-30 {
  margin-right: 30px !important;
}
.m-bottom-10 {
  margin-bottom: 10px !important;
}
.m-bottom-15 {
  margin-bottom: 15px !important;
}
.m-bottom-20 {
  margin-bottom: 20px !important;
}
.m-bottom-30 {
  margin-bottom: 30px !important;
}
.m-bottom-40 {
  margin-bottom: 40px !important;
}
.pseudo-link {
  text-decoration: none !important;
}
.pseudo-link:hover {
  color: #f90 !important;
}
.red-pseudo-link {
  color: #ff604f;
  border-bottom: 1px dotted #ff604f;
}
.red-variant-2-text {
  color: #ff604f;
}
.delete-link {
  text-decoration: none !important;
  color: #d2322d;
}
.delete-link:hover {
  text-decoration: none !important;
  color: #f2122d;
}
.text-color {
  color: #323232 !important;
}
.text-default {
  color: #74c02c;
}
.orange-highlight {
  border-color: #f90 !important;
  box-shadow: 0 0 0.5em #f90 !important;
}
.not-bold {
  font-weight: normal !important;
}
.text-bold {
  font-weight: bold;
}
.text-spaced {
  letter-spacing: 1.2px;
}
.text-italic {
  font-style: italic;
}
.row .top60 {
  margin-top: 60px !important;
}
.row .top-minus-10 {
  margin-top: -10px !important;
}
.row.bottom5 {
  margin-bottom: 5px;
}
.row.bottom20 {
  margin-bottom: 20px;
}
.row.bottom30 {
  margin-bottom: 30px;
}
.row.bottom40 {
  margin-bottom: 40px;
}
.row.bottom60 {
  margin-bottom: 60px;
}
.row.bottom80 {
  margin-bottom: 80px;
}
.row.bottom100 {
  margin-bottom: 100px;
}
hr.underfield {
  margin-top: 5px;
  margin-bottom: 0;
  border-color: #e5e5e5;
}
.value-field {
  font-size: 16px;
}
.under-label {
  font-size: 13px;
  color: #a2a29c;
}
li.editable {
  color: #74c02c;
}
.outer {
  display: table;
  position: absolute;
  height: 100%;
  width: 100%;
}
.middle {
  display: table-cell;
  vertical-align: middle;
}
.inner {
  margin-left: auto;
  margin-right: auto;
  width: 20px;
}
.top-buffer {
  margin-top: 15px;
}
.top-large-buffer {
  margin-top: 30px !important;
}
.border-padding {
  padding: 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.color-999 {
  color: #999;
}
.color-bbb {
  color: #bbb;
}
.opacity-50 {
  opacity: 0.5;
}
.relative {
  position: relative;
}
.clear-both {
  clear: both;
}
.link-without-decoration:link,
.link-without-decoration:visited {
  text-decoration: none;
}
.link-without-decoration:link:hover,
.link-without-decoration:visited:hover {
  text-decoration: none;
}
.bg-orange {
  background-color: #fcf8f2;
}
.lime-label {
  position: absolute;
  top: 37px;
  right: 15px;
  overflow: hidden;
  min-width: 120px;
  min-height: 10px;
  padding: 0 10px;
  background-color: #d9f3b5;
  text-align: center;
}
.mo-view-hidden {
  display: none;
}
.one-line-text {
  white-space: nowrap;
}
.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
