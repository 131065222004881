.entrance-exams-cell {
  text-align: center !important;
}
.entrance-exams-cell.entrance-exams-cell-editable {
  position: relative;
  cursor: pointer;
}
.entrance-exams-cell:hover .fa-plus,
.entrance-exams-cell:hover .fa-pencil {
  color: #74c02c;
}
.entrance-exams-cell .fa-plus,
.entrance-exams-cell .fa-pencil {
  color: #999;
}
.entrance-exams-cell .fa-pencil {
  position: absolute;
  top: 6px;
  right: 6px;
}
.entrance-exams-cell .exam-subject {
  color: #74c02c;
  border-bottom: 1px dotted;
}
