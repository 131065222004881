.select-filter {
  display: inline-block;
  min-width: 200px;
}
.select-filter .selectize-input {
  display: inline-block;
  padding: 12px 32px 12px 12px;
  background-color: #fbfaf8;
  border: 1px solid #d4d4d2;
  border-radius: 3px;
  z-index: 2;
}
.select-filter .selectize-input input::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}
.select-filter .selectize-input input::-moz-placeholder {
  color: #000;
  opacity: 1;
}
.select-filter .selectize-input input:-moz-placeholder {
  color: #000;
  opacity: 1;
}
.select-filter .selectize-input input:-ms-input-placeholder {
  color: #000;
  opacity: 1;
}
.select-filter .selectize-input.has-items input {
  display: none;
}
.select-filter .selectize-input.focus {
  box-shadow: none;
}
.select-filter .selectize-input.dropdown-active {
  border-bottom: 1px solid transparent;
  border-radius: 3px 3px 0 0;
}
.select-filter .selectize-input .remove {
  width: 11px;
  height: 12px;
  margin: 14px 25px;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  font-size: 12px;
  line-height: 12px;
  color: #ff604f;
}
.select-filter .selectize-input .remove::before {
  content: "\f00d";
}
.select-filter .selectize-dropdown {
  top: 39px !important;
  width: 500px !important;
  background-color: #fbfaf8;
  border: 1px solid #d4d4d2;
  border-radius: 0 3px 3px 3px;
  box-shadow: none;
  z-index: 1;
}
.select-filter .selectize-dropdown .selectize-dropdown-content {
  background-color: transparent;
}
.select-filter .selectize-dropdown .active {
  background-color: #e4e4e4;
}
.select-filter .selectize-dropdown [data-selectable] {
  padding: 10px;
  line-height: 19px;
}
.select-filter.selectize-control.single .selectize-input.input-active {
  background-color: #fbfaf8;
}
.select-filter.selectize-control.single .selectize-input::after,
.select-filter.selectize-control.single .selectize-input.dropdown-active::after {
  top: 44%;
  right: 26px;
  border: none 0;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  content: "\f107";
  color: #333;
}
.select-filter.selectize-control.single .selectize-input.dropdown-active::after {
  content: "\f106";
}
