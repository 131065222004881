.page-login {
  background-color: #fafafa;
}
.page-login .modal-title {
  padding-top: 150px;
  font-size: 2.5em;
  font-weight: bold;
  color: #7b7b7b;
  text-align: center;
  background: transparent url("/assets/images/logo.png") no-repeat 50% 0%;
}
.page-login .modal-title.mo-view {
  background: transparent url("/assets/images/region.png") no-repeat 50% 0%;
}
.page-login .region-name {
  margin-bottom: 50px;
  font-size: 15px;
  text-align: center;
  color: #666;
}
.page-login .l-wrapper .content-container {
  background-color: transparent;
  box-shadow: none;
}
.page-login .col-md-offset-4 {
  position: absolute;
  left: 0;
  top: 70px;
  right: 0;
  margin: 0 auto !important;
  padding: 25px;
  width: 450px;
  background-color: #fff;
  box-shadow: 1px 16px 18px rgba(0,0,0,0.25);
}
.page-login .navbar {
  display: none;
}
.page-login .btn:hover {
  background-color: #67ad27;
}
.page-login .form-login {
  margin-bottom: 10px;
}
.page-login .form-login .form-control {
  position: relative;
  margin-bottom: 1em;
  padding: 7px 10px;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1em;
}
.page-login .form-login .form-control:focus {
  z-index: 2;
}
.page-login .form-login button {
  padding: 7px 16px;
  font-size: 15px;
  outline: none;
}
.page-login .activation-modal {
  margin: 0 auto !important;
  padding: 25px;
  width: 450px;
  background-color: #fff;
  box-shadow: 1px 16px 18px rgba(0,0,0,0.25);
}
.page-login .activation-modal .dnevnik-logo {
  margin-top: 20px;
  height: 35px;
  background: transparent url("/assets/images/dnevnik_logo_small.png") no-repeat 10% 0%;
}
.page-login .activation-modal .region-emblem {
  position: absolute;
  top: -100px;
  margin-left: 44%;
  transform: translate(-51%, 0);
  box-shadow: 0 -6px 10px rgba(0,0,0,0.2);
}
.page-login .activation-modal .region-name {
  margin-top: 55px;
  margin-bottom: 10px;
  color: #999;
}
.page-login .activation-modal h2 {
  margin-bottom: 30px;
  padding: 0 30px;
  line-height: 35px;
  font-size: 22px;
  color: #666;
}
.page-login .activation-modal .tabs {
  height: 30px;
}
.page-login .activation-modal .tabs .tab {
  padding-bottom: 5px;
  border-bottom: 2px solid #ccc;
  color: #727272;
  cursor: pointer;
}
.page-login .activation-modal .tabs .tab.active {
  border-bottom-color: #74c02c;
  color: #212121;
  cursor: default;
}
.page-login .activation-modal .check-message {
  margin: 15px 0 35px;
  color: #666;
}
.page-login .activation-modal .form-group {
  margin-bottom: 35px;
}
.page-login .activation-modal .form-readonly input {
  pointer-events: none;
}
.page-login .gosuslugi-btn {
  display: inline-block;
  width: 100%;
  background: #fff;
  border: 1px solid #0d4cd3;
  line-height: 34px;
  font-size: 15px;
  color: #0d4cd3 !important;
  text-align: center;
  text-decoration: none;
}
.page-login .gosuslugi-btn .gosuslugi-logo {
  display: inline-block;
  width: 40px;
  height: 32px;
  margin-bottom: 1px;
  vertical-align: bottom;
  background: url("/assets/images/background/gosuslugi-logo.svg") center no-repeat;
  background-size: 36px 36px;
}
.page-login .gosuslugi-btn:not(.disabled):hover {
  box-shadow: inset 0 1px 1px rgba(13,76,211,0.075), 0 0 6px rgba(13,76,211,0.5);
}
.page-login .gosuslugi-btn.disabled {
  border-color: #ccc;
  color: #ccc !important;
  cursor: not-allowed;
}
.page-login .gosuslugi-btn.disabled:active {
  pointer-events: none;
}
.page-login .gosuslugi-btn.disabled .gosuslugi-logo {
  opacity: 0.3;
}
