.order-container {
  padding: 0 15px 50px;
  font-family: "Roboto";
  color: #000;
}
.order-container .order-org-name {
  margin: 20px 0 50px 0;
  font-size: 16px;
  line-height: 24px;
  color: #8a8a8a;
}
.order-container .order-year {
  margin: 40px 0 0px 0;
  font-size: 12px;
  line-height: 20px;
  color: #8a8a8a;
  font-variant: small-caps;
}
.order-container .order-label {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: #000;
}
.order-container .order-title {
  margin: 30px 0 60px;
  font-size: 18px;
  line-height: 21px;
}
.order-container .order-base,
.order-container .order-command,
.order-container .order-speciality,
.order-container .order-department {
  font-size: 16px;
  line-height: 28px;
}
.order-container .order-speciality-block {
  margin-bottom: 30px;
}
.order-container .order-separate-speciality {
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 21px;
}
.order-container .order-command {
  margin-top: 40px;
  letter-spacing: 1px;
}
.order-container .order-speciality,
.order-container .order-department {
  margin: 35px 0 50px;
}
.order-specialities-accordion .spec-block-closed.info-panel-title {
  border-color: #ddd;
}
.order-specialities-accordion .spec-block-closed .speciality-summary-info {
  color: #999;
}
.order-specialities-accordion .spec-block-closed.speciality-block {
  display: none;
}
.order-specialities-accordion .spec-without-groups-or-students.info-panel-title {
  border-color: #ddd;
}
.order-specialities-accordion .spec-without-groups-or-students .speciality-title {
  margin-left: 30px;
}
