html,
body {
  height: 100%;
}
body {
  min-width: 980px;
  background-color: #fff;
  font-family: TextbookNew-Regular;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
}
h1 {
  margin-bottom: 40px;
}
h4 {
  margin: 10px 0;
}
.app-wrapper {
  height: inherit;
}
.l-wrapper {
  position: relative;
  min-height: 100%;
  background-color: #fafafa;
}
.l-wrapper .content-container {
  padding-top: 100px;
  background-color: #fff;
  box-shadow: 0 0 7px rgba(0,0,0,0.35);
}
#main-region {
  margin-top: 20px;
}
#loading-region {
  position: fixed;
  top: 100px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  background-color: #000;
  opacity: 0.5;
}
#dialog-region {
  display: none;
}
#overlay-region {
  background: #fff;
}
#filters-region span.delete-filter {
  display: none;
  margin-left: 5px;
}
#filters-region div.filter-item.custom:hover span.delete-filter {
  display: inline;
}
#spinner {
  height: 100%;
}
#spinner .spinner {
  left: 50% !important;
  top: 50% !important;
}
.page-header {
  margin: 40px 0;
  color: #000;
  font-size: 24px;
  font-weight: 500;
}
h1.page-header {
  padding-bottom: 0;
  border: 0 none;
  font-size: 24px;
}
h1.page-header input {
  margin-right: 0.25em;
}
.page-header.flagged-cell input {
  height: 16px;
}
.page-header.height-30 {
  line-height: 30px;
}
.page-header ~ .page-subheader {
  margin-top: -18px;
  margin-bottom: 25px;
}
@media (min-width: 1px) {
  .backgrid th,
  .backgrid td {
    max-width: 155px;
  }
}
@media (min-width: 768px) {
  .float-toolbar.stuck {
    width: 720px;
  }
}
@media (min-width: 992px) {
  .float-toolbar.stuck {
    width: 940px;
  }
  #search-region fieldset div {
    max-width: 261px;
  }
}
@media (min-width: 1200px) {
  .float-toolbar.stuck {
    width: 1140px;
  }
}
.col-md-auto {
  width: auto;
  height: 100%;
  float: left;
  margin-right: 0;
}
.page-with-sidebar {
  display: flex;
}
