.personal-card-container {
  padding: 0 15px 80px;
  font-family: "Roboto";
  color: #000;
}
.personal-card-container .personal-card-number-container {
  margin-bottom: 24px;
  height: 36px;
}
.personal-card-container .personal-card-number-container .backbone-form .material-input .placeholder-label {
  top: 3px;
  font-size: 20px;
  line-height: 24px;
  color: #ccc;
}
.personal-card-container .personal-card-number-container .personal-card-number {
  margin-right: 4px;
  font-size: 20px;
  line-height: 24px;
  color: #000;
}
.personal-card-container .personal-card-number-container .personal-card-number .personal-card-number-empty {
  color: #ccc;
}
.personal-card-container .personal-card-number-container .fa {
  padding: 6px;
  vertical-align: top;
  color: #999;
  cursor: pointer;
}
.personal-card-container .personal-card-number-container .personal-card-number-edit-form {
  display: inline-block;
  margin-right: 10px;
  width: 140px;
}
.personal-card-container .personal-card-number-container .personal-card-number-edit-form .material-input {
  margin-top: 0;
}
.personal-card-container .personal-card-number-container .personal-card-number-edit-form .material-input .form-control {
  margin-top: -3px;
  padding: 0;
  font-size: 20px;
  line-height: 24px;
  color: #000;
}
.personal-card-container .personal-card-number-container .btn {
  padding: 5px 18px;
  font-weight: 500;
  line-height: 16px;
  vertical-align: top;
}
.personal-card-container .personal-card-document {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  background-color: #fffbfa;
  background-image: url("/assets/images/background/document_wave.png");
}
.personal-card-container .personal-card-document .personal-card-document-title {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 20px;
}
.personal-card-container .personal-card-document.personal-card-document-authenticate {
  height: 210px;
  border-bottom: 5px dashed #f9bcc0;
}
.personal-card-container .personal-card-document.personal-card-document-authenticate.personal-card-document-education {
  border-bottom: 5px solid #b9e4ff;
}
.personal-card-container .personal-card-document.personal-card-document-authenticate .personal-card-document-title {
  width: calc(100% - 140px);
  margin-bottom: 32px;
  float: left;
}
.personal-card-container .personal-card-document.personal-card-document-authenticate .personal-card-document-number {
  color: #803238;
  font-family: "PT Serif";
  font-size: 16px;
  letter-spacing: 1.14px;
  line-height: 20px;
  float: right;
}
.personal-card-container .personal-card-document.personal-card-document-snils {
  position: relative;
  height: 85px;
  padding: 18px 20px;
  background-color: #e7f2da;
}
.personal-card-container .personal-card-document.personal-card-document-snils .personal-card-document-title {
  margin-bottom: 9px;
  font-size: 14px;
}
.personal-card-container .personal-card-document.personal-card-document-snils .personal-card-document-number {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.14px;
  line-height: 20px;
}
.personal-card-container .personal-card-document.personal-card-document-snils:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 60px;
  bottom: 0;
  width: 75px;
  background-color: #d0e4ba;
  background-image: url("/assets/images/background/document_wave.png");
}
.personal-card-container .personal-card-document.personal-card-document-inn {
  height: 85px;
  padding: 18px 20px;
  background-color: #fffcfa;
}
.personal-card-container .personal-card-document.personal-card-document-inn .personal-card-document-title {
  font-size: 14px;
}
.personal-card-container .personal-card-document.personal-card-document-inn .personal-card-document-number {
  font-family: "PT Serif";
  font-size: 16px;
  letter-spacing: 1.14px;
  line-height: 23px;
}
.personal-card-container .personal-card-document.personal-card-document-inn .personal-card-document-number td {
  padding: 0 7px;
  border: 1px solid #000;
}
