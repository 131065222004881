.editable-field-container .backbone-form .material-input .placeholder-label {
  top: 3px;
  color: #ccc;
}
.editable-field-container .editable-field-readonly-value {
  margin-right: 4px;
  color: #000;
}
.editable-field-container .fa.fa-pencil {
  padding: 3px 12px;
  color: #999;
  cursor: pointer;
}
.editable-field-container .editable-field-form {
  display: inline-block;
  margin-right: 10px;
  width: 175px;
}
.editable-field-container .editable-field-form .material-input {
  margin-top: 0;
}
.editable-field-container .editable-field-form .material-input .form-control {
  margin-top: -3px;
  padding: 0;
  color: #000;
}
.editable-field-container .btn {
  padding: 5px 18px;
  font-weight: 500;
  line-height: 16px;
  vertical-align: top;
}
.petition-attach-stamp {
  display: inline-block;
  margin-left: 12px;
  width: 26px;
  height: 30px;
  background: url("/assets/images/background/stamp.png") no-repeat;
  background-size: 26px 30px;
  vertical-align: middle;
}
