.timeline-event {
  position: relative;
  margin-top: 30px;
}
.timeline-event .green {
  background-color: #d9f3b5;
}
.timeline-event .blue {
  background-color: #b9e4ff;
}
.timeline-event .brown {
  background-color: #efe4b0;
}
.timeline-event .circle {
  margin-top: 5px;
  width: 41px;
  height: 41px;
  border-radius: 100px;
  float: left;
}
.timeline-event .circle > .inner-circle {
  position: relative;
  height: 35px;
  width: 35px;
  padding: 3px 0 0 1px;
  border: 3px solid #fff;
  border-radius: 100px;
  top: 3px;
  left: 3px;
  text-align: center;
  background-color: transparent;
}
.timeline-event .circle > .inner-circle .fa {
  font-size: 13px;
  line-height: 13px;
  color: #94a67b;
}
.timeline-event .line {
  position: absolute;
  height: 100%;
  top: 44px;
  width: 2px;
  left: 20px;
  z-index: 0;
}
.timeline-event .arrow {
  width: 0;
  height: 0;
  margin: 15px 0 0 15px;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #f4f4f4 transparent transparent;
  float: left;
}
.timeline-event .block {
  width: calc(100% - 67px);
  padding: 15px 20px;
  background-color: #f4f4f4;
  line-height: 20px;
  float: left;
}
.timeline-event .attach-icon {
  display: inline-block;
  width: 20px;
  height: 26px;
  margin-right: 10px;
  vertical-align: middle;
  background: transparent url("/assets/images/attach.png") no-repeat 0 0;
  background-size: 20px 26px;
}
.timeline-event .attach-create-date {
  margin-top: 12px;
  color: #aaa;
}
