.backbone-form .material-input {
  position: relative;
  margin-top: 20px;
}
.backbone-form .material-input input[type="text"],
.backbone-form .material-input input[type="password"] {
  padding: 4px 0 2px;
  border-width: 0 0 1px;
  border-color: #ccc;
  background-color: transparent;
}
.backbone-form .material-input input[type="text"]:valid ~ .active-label,
.backbone-form .material-input input[type="password"]:valid ~ .active-label,
.backbone-form .material-input input[type="text"][readonly] ~ .active-label,
.backbone-form .material-input input[type="password"][readonly] ~ .active-label,
.backbone-form .material-input input[type="text"]:focus ~ .active-label,
.backbone-form .material-input input[type="password"]:focus ~ .active-label {
  top: -15px;
}
.backbone-form .material-input input[type="text"]:valid ~ .active-label.required-label:after,
.backbone-form .material-input input[type="password"]:valid ~ .active-label.required-label:after,
.backbone-form .material-input input[type="text"][readonly] ~ .active-label.required-label:after,
.backbone-form .material-input input[type="password"][readonly] ~ .active-label.required-label:after,
.backbone-form .material-input input[type="text"]:focus ~ .active-label.required-label:after,
.backbone-form .material-input input[type="password"]:focus ~ .active-label.required-label:after {
  color: #8a8a8a;
}
.backbone-form .material-input input[type="text"]:valid ~ .placeholder-label,
.backbone-form .material-input input[type="password"]:valid ~ .placeholder-label,
.backbone-form .material-input input[type="text"][readonly] ~ .placeholder-label,
.backbone-form .material-input input[type="password"][readonly] ~ .placeholder-label,
.backbone-form .material-input input[type="text"]:focus ~ .placeholder-label,
.backbone-form .material-input input[type="password"]:focus ~ .placeholder-label {
  width: 0;
  white-space: nowrap;
  color: transparent;
}
.backbone-form .material-input input[type="text"]:valid ~ .placeholder-label.required-label:after,
.backbone-form .material-input input[type="password"]:valid ~ .placeholder-label.required-label:after,
.backbone-form .material-input input[type="text"][readonly] ~ .placeholder-label.required-label:after,
.backbone-form .material-input input[type="password"][readonly] ~ .placeholder-label.required-label:after,
.backbone-form .material-input input[type="text"]:focus ~ .placeholder-label.required-label:after,
.backbone-form .material-input input[type="password"]:focus ~ .placeholder-label.required-label:after {
  color: transparent;
}
.backbone-form .material-input input[type="text"]:valid ~ .active-label,
.backbone-form .material-input input[type="password"]:valid ~ .active-label {
  color: #ccc;
  pointer-events: auto;
}
.backbone-form .material-input input[type="text"][readonly],
.backbone-form .material-input input[type="password"][readonly] {
  opacity: 0.8;
  pointer-events: none;
}
.backbone-form .material-input input[type="text"][readonly] ~ .active-label,
.backbone-form .material-input input[type="password"][readonly] ~ .active-label {
  color: #ccc;
  pointer-events: none;
}
.backbone-form .material-input input[type="text"]:focus,
.backbone-form .material-input input[type="password"]:focus,
.backbone-form .material-input input[type="text"].show-form-control-tooltip,
.backbone-form .material-input input[type="password"].show-form-control-tooltip {
  border-bottom-color: transparent;
  box-shadow: none;
}
.backbone-form .material-input input[type="text"]:focus ~ .active-label,
.backbone-form .material-input input[type="password"]:focus ~ .active-label,
.backbone-form .material-input input[type="text"].show-form-control-tooltip ~ .active-label,
.backbone-form .material-input input[type="password"].show-form-control-tooltip ~ .active-label {
  color: #74c02c;
}
.backbone-form .material-input input[type="text"]:focus ~ .bar:before,
.backbone-form .material-input input[type="password"]:focus ~ .bar:before,
.backbone-form .material-input input[type="text"].show-form-control-tooltip ~ .bar:before,
.backbone-form .material-input input[type="password"].show-form-control-tooltip ~ .bar:before {
  width: 100%;
}
.backbone-form .material-input input[type="text"]:focus ~ .form-control-tooltip:not(:empty),
.backbone-form .material-input input[type="password"]:focus ~ .form-control-tooltip:not(:empty),
.backbone-form .material-input input[type="text"].show-form-control-tooltip ~ .form-control-tooltip:not(:empty),
.backbone-form .material-input input[type="password"].show-form-control-tooltip ~ .form-control-tooltip:not(:empty) {
  opacity: 0.7;
  z-index: 1000;
}
.backbone-form .material-input label {
  position: absolute;
  top: 6px;
  color: #999;
  font-size: 14px;
  pointer-events: none;
  transition: 0.3s ease-in-out all;
}
.backbone-form .material-input .active-label {
  color: transparent;
}
.backbone-form .material-input .active-label.required-label:after {
  color: transparent;
}
.backbone-form .material-input .bar {
  position: relative;
  display: block;
  width: 100%;
}
.backbone-form .material-input .bar:before {
  position: absolute;
  bottom: -1px;
  content: '';
  height: 2px;
  width: 0;
  background: #74c02c;
  transition: 0.3s ease-in-out all;
}
.backbone-form .material-input .form-control-tooltip {
  position: absolute;
  top: 55px;
  padding: 7px;
  width: 100%;
  background-color: #000;
  color: #fff;
  text-align: center;
  line-height: 16px;
  opacity: 0;
  transition: 0.3s ease-in-out all;
}
.backbone-form .material-input .form-control-tooltip:before {
  position: absolute;
  content: " ";
  top: -16px;
  right: 50%;
  border: medium solid transparent;
  border-width: 8px;
  border-bottom-color: #000;
}
.backbone-form .material-input.has-error .form-control {
  border-bottom-color: #d9534f;
  box-shadow: none;
}
.backbone-form .material-input .selectize-control {
  height: 31px;
  padding-top: 3px;
}
.backbone-form .material-input .selectize-control.single .selectize-input,
.backbone-form .material-input .selectize-control.multi .selectize-input,
.backbone-form .material-input .selectize-control.single .selectize-input.dropdown-active,
.backbone-form .material-input .selectize-control.multi .selectize-input.dropdown-active {
  padding: 0;
  border: 0 none;
  box-shadow: none;
}
.backbone-form .material-input .selectize-control.single .selectize-input:before,
.backbone-form .material-input .selectize-control.multi .selectize-input:before,
.backbone-form .material-input .selectize-control.single .selectize-input.dropdown-active:before,
.backbone-form .material-input .selectize-control.multi .selectize-input.dropdown-active:before {
  position: absolute;
  right: auto;
  bottom: 2px;
  left: auto;
  display: block;
  content: '';
  width: 0;
  height: 2px;
  background: #74c02c;
  z-index: 1;
  transition: 0.3s ease-in-out all;
}
.backbone-form .material-input .selectize-control.single .selectize-input:after,
.backbone-form .material-input .selectize-control.multi .selectize-input:after,
.backbone-form .material-input .selectize-control.single .selectize-input.dropdown-active:after,
.backbone-form .material-input .selectize-control.multi .selectize-input.dropdown-active:after {
  margin-top: -3px;
  right: 0;
  border-color: transparent transparent #ccc transparent;
  border-width: 0 0 10px 10px;
  transition: 0.3s ease-in-out all;
}
.backbone-form .material-input .selectize-control.single .selectize-input input,
.backbone-form .material-input .selectize-control.multi .selectize-input input,
.backbone-form .material-input .selectize-control.single .selectize-input.dropdown-active input,
.backbone-form .material-input .selectize-control.multi .selectize-input.dropdown-active input {
  position: absolute !important;
  left: 0 !important;
  padding: 4px 0 !important;
  width: 100% !important;
  outline: 0;
  border-style: solid !important;
  border-width: 0 0 1px !important;
  border-color: #ccc !important;
  color: #999;
  opacity: 1 !important;
}
.backbone-form .material-input .selectize-control.single .selectize-input .item,
.backbone-form .material-input .selectize-control.multi .selectize-input .item,
.backbone-form .material-input .selectize-control.single .selectize-input.dropdown-active .item,
.backbone-form .material-input .selectize-control.multi .selectize-input.dropdown-active .item {
  position: absolute;
  bottom: 7px;
}
.backbone-form .material-input .selectize-control.single .selectize-input.input-active:before,
.backbone-form .material-input .selectize-control.multi .selectize-input.input-active:before {
  width: 100%;
}
.backbone-form .material-input .selectize-control.single .selectize-input.input-active:after,
.backbone-form .material-input .selectize-control.multi .selectize-input.input-active:after {
  border-color: transparent transparent #74c02c transparent;
}
.backbone-form .material-input .selectize-control.single .selectize-input.input-active input,
.backbone-form .material-input .selectize-control.multi .selectize-input.input-active input {
  color: #555;
}
.backbone-form .material-input .selectize-control.single .selectize-dropdown,
.backbone-form .material-input .selectize-control.multi .selectize-dropdown {
  display: inline !important;
  transform-origin: top left;
  transform: scale(0);
  transition: transform 0.3s ease-in-out 0s;
}
.backbone-form .material-input .selectize-control.single .selectize-input.dropdown-active ~ .selectize-dropdown,
.backbone-form .material-input .selectize-control.multi .selectize-input.dropdown-active ~ .selectize-dropdown {
  transform: scale(1);
}
.backbone-form .material-input .selectize-control .active-label,
.backbone-form .material-input .selectize-control .placeholder-label {
  left: 0;
  top: 6px;
  z-index: 1;
}
.backbone-form .material-input .selectize-control .active-label.required-label:after {
  color: transparent;
}
.backbone-form .material-input .selectize-control .selectize-input.has-items ~ .active-label,
.backbone-form .material-input .selectize-control .selectize-input.focus ~ .active-label {
  top: -18px;
}
.backbone-form .material-input .selectize-control .selectize-input.has-items ~ .active-label.required-label:after,
.backbone-form .material-input .selectize-control .selectize-input.focus ~ .active-label.required-label:after {
  color: #8a8a8a;
}
.backbone-form .material-input .selectize-control .selectize-input.has-items ~ .placeholder-label,
.backbone-form .material-input .selectize-control .selectize-input.focus ~ .placeholder-label {
  width: 0;
  white-space: nowrap;
  color: transparent;
}
.backbone-form .material-input .selectize-control .selectize-input.has-items ~ .placeholder-label.required-label:after,
.backbone-form .material-input .selectize-control .selectize-input.focus ~ .placeholder-label.required-label:after {
  color: transparent;
}
.backbone-form .material-input .selectize-control .selectize-input.has-items ~ .active-label {
  color: #ccc;
  pointer-events: auto;
}
.backbone-form .material-input .selectize-control .selectize-input.focus ~ .active-label {
  color: #74c02c;
}
.backbone-form .material-input .selectize-control .selectize-input.focus ~ .bar:before {
  width: 100%;
}
.backbone-form .material-input.has-error .selectize-control .selectize-input input {
  border-bottom-color: #d9534f !important;
}
.backbone-form .material-input.has-error .input-group.date .input-group-addon {
  border-color: transparent;
  color: transparent;
  background-color: transparent;
}
.backbone-form .material-input .input-group.date .form-control {
  width: calc(100% + 30px);
  padding-right: 30px;
  float: none;
}
.backbone-form .material-input .input-group.date .bar {
  width: calc(100% + 30px);
}
.backbone-form .material-input .input-group.date .input-group-addon {
  border: 0 none;
  vertical-align: bottom;
}
.backbone-form .material-input .input-group.date .input-group-addon .fa.fa-calendar {
  margin: 0 4px 10px 12px;
  width: 14px;
  height: 14px;
  background: none;
  color: #a9a7a7;
  cursor: pointer;
}
.backbone-form .material-input .input-group.date .input-group-addon .fa.fa-calendar:before {
  content: "\f073";
}
.backbone-form .material-input .input-group.date .input-group-addon .fa.fa-clock-o {
  margin-bottom: 9px;
}
.backbone-form .material-input-without-title .material-input {
  margin-top: -6px;
}
.backbone-form .right-aligned .material-input input {
  text-align: right;
}
.backbone-form .right-aligned .material-input .placeholder-label,
.backbone-form .right-aligned .material-input .active-label {
  right: 0;
}
.checkbox-label.required-label:after {
  content: ' *';
  color: #8a8a8a;
}
.toggle-switch {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
.toggle-switch .checkbox-label {
  font-size: 14px;
  vertical-align: top;
  cursor: pointer;
}
.toggle-switch input {
  display: none;
}
.toggle-switch input:checked + label:before {
  border-color: #74c02c;
  background-color: #74c02c;
  left: 20px;
}
.toggle-switch input:checked + label:after {
  background-color: #74c02c;
}
.toggle-switch label {
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 10px 0;
  width: 36px;
  cursor: pointer;
  float: right;
}
.toggle-switch label:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
  z-index: 1;
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), border 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.toggle-switch label:after {
  content: '';
  position: absolute;
  width: 36px;
  height: 2px;
  background-color: #ccc;
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.toggle-switch.toggle-switch-disabled input:checked + label:before {
  border-color: #bbb;
  background-color: #bbb;
}
.toggle-switch.toggle-switch-disabled input:checked + label:after {
  background-color: #bbb;
}
.toggle-switch.toggle-switch-disabled label {
  cursor: not-allowed;
}
.top-space-for-toggle-switch .toggle-switch {
  margin-top: 27px;
}
.checkbox-green {
  font-weight: normal;
  cursor: pointer;
}
.checkbox-green input {
  display: none;
}
.checkbox-green input:checked + .flaticon-check52 {
  color: #74c02c;
}
.checkbox-green input:checked ~ .checkbox-label {
  color: #000;
}
.checkbox-green .flaticon-check52 {
  margin-right: 10px;
  color: #bbb;
}
.checkbox-green .flaticon-check52:before {
  font-size: 16px;
}
.checkbox-green .checkbox-label {
  font-size: 14px;
  color: #bbb;
}
.checkbox-green.checkbox-green-inline {
  width: 100%;
}
.checkbox-green.checkbox-green-inline .checkbox-green-inline-title {
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
}
.checkbox-green.checkbox-green-disabled {
  cursor: auto;
}
.checkbox-green.checkbox-green-disabled .checkbox-label {
  opacity: 0.8;
}
.comment-tip-container {
  position: relative;
}
.comment-tip-container:hover .comment-tip {
  display: block;
}
.comment-tip-container .comment-tip {
  position: absolute;
  display: none;
  top: auto;
  right: 0;
  bottom: 25px;
  left: auto;
  height: 45px;
  border: 1px solid #fbfaf8;
  border-radius: 3px;
  padding: 10px 20px;
  background: #fbfaf8;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.3);
  white-space: nowrap;
}
.comment-tip-container .comment-tip:before {
  position: absolute;
  display: block;
  top: 101%;
  left: 47%;
  width: 0;
  height: 0;
  border-color: #cccbc9 transparent transparent;
  border-style: solid;
  border-width: 11px 11px 0;
  box-sizing: border-box;
  content: " ";
}
.comment-tip-container .comment-tip:after {
  position: absolute;
  display: block;
  top: 101%;
  left: 47%;
  width: 0;
  height: 0;
  border-color: #fbfaf8 transparent transparent;
  border-style: solid;
  border-width: 10px 10px 0;
  box-sizing: border-box;
  content: " ";
}
.comment-tip-container .comment-tip.left-side {
  top: -85%;
  right: 140%;
}
.comment-tip-container .comment-tip.left-side:before {
  top: 25%;
  right: -23px;
  left: auto;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 11px solid #cccbc9;
}
.comment-tip-container .comment-tip.left-side:after {
  top: 25%;
  right: -21px;
  left: auto;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #fbfaf8;
}
.comment-tip-container .comment-tip.bottom-side {
  top: calc(100% + 20px);
  right: auto;
}
.comment-tip-container .comment-tip.bottom-side:before {
  top: -53%;
  right: auto;
  left: 47%;
  border-left: 11px solid transparent;
  border-top: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #cccbc9;
}
.comment-tip-container .comment-tip.bottom-side:after {
  top: calc(-51% + 1px);
  right: auto;
  left: calc(47% + 1px);
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fbfaf8;
}
