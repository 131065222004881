@font-face {
	font-family: "Flaticon";
	src: url("/assets/fonts/flaticon.eot");
	src: url("/assets/fonts/flaticon.eot#iefix") format("embedded-opentype"),
	url("/assets/fonts/flaticon.woff") format("woff"),
	url("/assets/fonts/flaticon.ttf") format("truetype"),
	url("/assets/fonts/flaticon.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-size: 20px;
	font-style: normal;
}.flaticon-check52:before {
	content: "\e000";
}
