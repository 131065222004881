.text-box {
  font-size: 14px;
  color: #666;
  padding: 15px;
  border-left: 2px solid;
}
.text-box .title {
  font-size: 16px;
}
.text-box.info-box {
  background-color: #f4f8fa;
  border-color: #2ec1cc;
}
.text-box.info-box .title {
  color: #2ec1cc;
}
