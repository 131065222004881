
/* vendor styles */
@import "build/vendor/bootstrap.css";
@import "vendor/backgrid.css";
@import "vendor/backgrid.paginator.min.css";
@import "vendor/selectize.bootstrap3.css";
@import "vendor/bootstrap-datetimepicker.css";

/* fonts */
@import "vendor/font-awesome.css";
@import "vendor/flaticon.css";

/* own styles */
@import "build/base/vendor-overwrite.css";
@import "build/base/layout.css";
@import "build/base/state.css";
@import "build/base/login.css";
@import "build/views/personal-card.css";
@import "build/views/order.css";
@import "build/views/petition.css";
@import "build/controls/form.css";
@import "build/controls/material.css";
@import "build/controls/timeline.css";
@import "build/controls/selectFilter.css";
@import "build/controls/all.css";
@import "build/controls/cell.css";
@import "build/controls/button.css";
@import "build/controls/message.css";
